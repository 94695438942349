<template>
	<h1>
		Welcome to the Pennytel Portal
		<template v-if="managingCustomer">for <span id="selected-customer">{{managingCustomerName}}</span></template>
	</h1>
</template>

<script>
	import {mapGetters} from 'vuex';
	
	export default {
		computed: {
			...mapGetters(['managingCustomer', 'managingCustomerName'])
		}
	}
</script>

<style scoped lang="scss">
	#selected-customer {
		font-style:italic;
	}
</style>